body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card img{
  width: 100% !important;
  height: 300px !important;
}

.contact-info{
  margin-left: 60px;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .contact-info{
    margin-left: 0px;
  }
}

.mobile-header{
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #841810;
}

.custom-header {
  display: flex;
  justify-content: flex-end; 
}

.custom-header .btn-close{
  background-color: #f4b328;
  padding: 20px;
  color: white;
  border-radius: 50%;

}

.custom-offcanvas{
  max-width: 60% !important;
}
.custom-offcanvas .active {
  position: relative;  
  color: #200769;
  text-transform: uppercase;
  font-weight: bolder;
}

.custom-offcanvas .active:before {
  content: "...";
  position: absolute;
  left: 12%;
  top: -50%;
  transform: translateX(-100%); 
  color: #f4b328;
  font-size: 34px;
  text-align: center;
}


.custom-offcanvas a{
  border-bottom: 1px solid grey;
  padding-block: 15px;
}

hr{
  margin: 0 !important;
  padding-block: 13px !important;
}

.border-none{
  border: none !important;
}

.uppercase {
  text-transform: uppercase;
}

.bold{
  font-weight: bold !important;
}

.admission, .admission p{
  font-size: 15px !important;
  font-weight: normal !important;
}

.footer-reveal {
  animation: slide-up 0.5s ease-out forwards !important;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .powered_by{
    font-size: 15px !important;
  }
  .copyright_text{
    font-size: 15px !important;
  }
}

.carousel-indicators button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  opacity: 0.7 !important;
}

.carousel-indicators .active {
  background-color: #000 !important;
  opacity: 1 !important;
}

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition for hover effects */
}

.card:hover {
  transform: translateY(-10px); /* Lifts the card up slightly */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a stronger shadow */
}

.course-image{
  width: 100%;
  height: 440px;
}

.card-footer{
  background-color: white !important;
}



/* DepartmentsPage.css */
.departments-page {
  padding-top: 20px;
  padding-bottom: 30px;
}

.page-header {
  background-color: #f8f9fa; /* Light grey background */
  padding: 50px 0;
  text-align: center;
}

.page-header .page-name {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 10px;
}

.departments-listing {
  padding-top: 20px;
}

.department-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: white; /* White background for department items */
}

.department-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}


.department-image {
  height: 400px; /* Fixed height for images */
  overflow: hidden;
}

.department-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.department-content {
  padding: 20px;
}

.department-content h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: black; 
}

.department-description {
  color: #555;
  line-height: 1.6;
}

.department-head {
  font-weight: bold;
  margin-bottom: 5px;
}

.department-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  text-align: right;
}
/* DepartmentDetailsPage.css - Modern & Elegant University Style - Updated Colors */

.department-details-page {
  font-family: 'Arial', sans-serif; /* Modern, readable font */
  color: #333;
  background-color: #f9f9f9; /* Very light grey background for overall page */
  padding-top: 0;
  padding-bottom: 60px;
}

/* Hero Section - Department Name Banner - UPDATED BACKGROUND TO GRAY */
.department-hero-section {
  background-color: #f0f0f0; /* Light gray background for hero section */
  color: #200769; /* Use your dark blue for text on gray background */
  padding: 80px 0;
  text-align: center;
  margin-bottom: 30px;
}

.department-hero-title {
  font-size: 3.5em;
  font-weight: bold;
  letter-spacing: -1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
}

/* Main Department Details Section */
.department-details-section {
  background-color: white;
  padding: 30px 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 30px;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* Section Headings - UPDATED PRIMARY COLOR */
.section-heading, .sidebar-heading {
  font-size: 2.2em;
  font-weight: bold;
  color: #200769; /* Your primary dark blue color for headings */
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 10px;
  display: inline-block;
}

.section-heading::after, .sidebar-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 3px;
  background-color: #f4b328; /* Your yellow color for underline accent */
}

/* Department Overview Area */
.department-overview-area {
  margin-bottom: 40px;
}

.department-featured-image {
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.department-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 25px;
}

/* Head of Department Message Area - UPDATED BACKGROUND COLOR */
.head-message-area {
  background-color: #f9f9f9; /* Light grey, similar to page background */
  padding: 25px 30px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.head-message-content {
  font-style: italic;
  color: #495057;
}

/* Head of Department Name - UPDATED COLOR */
.head-name {
  font-weight: bold;
  font-style: normal;
  color: #200769; /* Your primary dark blue color for head's name */
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.head-message-text {
  font-size: 1.05rem;
  line-height: 1.7;
  white-space: pre-line;
}

/* Department Sidebar Area */
.department-sidebar-area {
  padding-left: 30px;
}

.sidebar-heading {
  margin-bottom: 20px;
}

/* Contact Information Section in Sidebar */
.department-contact-info {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

.contact-list {
  list-style: none;
  padding-left: 0;
}

.contact-item {
  margin-bottom: 12px;
  color: #555;
  font-size: 1rem;
}

.contact-item strong {
  font-weight: 600;
  color: #333;
}

/* Contact Link Color - UPDATED COLOR */
.contact-item a {
  color: #841810; /* Your dark red color for links */
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

/* Courses Offered Section in Sidebar */
.department-courses-area {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

.courses-list {
  list-style: none;
  padding-left: 0;
}

.courses-list li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  font-size: 1rem;
  color: #555;
}

/* Course Bullet Point Color - UPDATED COLOR */
.courses-list li::before {
  content: '\2022';
  position: absolute;
  left: 0;
  top: 4px;
  color: #f4b328; /* Your yellow color for course bullet points */
}

/* Responsive Adjustments (Basic) */
@media (max-width: 992px) {
  .department-hero-title {
    font-size: 2.8em;
  }
  .section-heading, .sidebar-heading {
    font-size: 2em;
  }
  .department-sidebar-area {
    padding-left: 0;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .department-hero-section {
    padding: 60px 0;
  }
  .department-hero-title {
    font-size: 2.2em;
  }
  .section-heading, .sidebar-heading {
    font-size: 1.8em;
  }
  .department-details-section {
    padding: 20px 0;
  }
}

/* DepartmentsPage.css - Elegant and Fancy Department Tiles */

.departments-page {
  padding-top: 30px; /* Increased top padding */
  padding-bottom: 40px; /* Increased bottom padding */
  background-color: #f9f9f9; /* Light grey background */
  font-family: 'Arial', sans-serif; /* Elegant font */
  color: #333;
}

.page-header {
  background-color: #f0f0f0; /* Light grey header background */
  padding: 60px 0; /* Adjusted header padding */
  text-align: center;
  margin-bottom: 40px; /* Spacing below header */
}

.page-header .page-name {
  font-size: 3.0em; /* Slightly larger page name */
  color: #200769; /* Dark blue title color */
  margin-bottom: 15px;
  font-weight: bold; /* Bold title */
  letter-spacing: -0.5px;
}

.page-header p {
  font-size: 1.1em;
  color: #555; /* Slightly muted description color */
  max-width: 700px; /* Limit description width */
  margin: 0 auto; /* Center description */
  line-height: 1.7;
}

.departments-listing {
  padding-top: 20px;
}

.department-item {
  background-color: #fff; /* White tile background */
  border-radius: 12px; /* More rounded corners */
  overflow: hidden;
  margin-bottom: 25px; /* Increased spacing between tiles */
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08); /* Softer, wider shadow */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 1px solid #e8e8e8; /* Very light subtle border */
}

.department-item:hover {
  transform: translateY(-8px); /* Slightly more pronounced lift on hover */
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12); /* Enhanced shadow on hover */
}

/* Remove default link styling from the anchor tag */
.departments-listing .col-lg-4.col-md-6.mb-4 > a.rounded-pill {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color from parent */
  display: block; /* Make anchor fill the container */
  border-radius: inherit !important; /* Ensure rounded corners are inherited */
  overflow: hidden; /* Clip any content that might overflow rounded corners */
}

.department-image {
  height: 350px; /* Slightly taller image area */
  overflow: hidden;
}

.department-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease-out; /* Smooth image zoom on hover */
}

/* Hover effect for images (optional - if you want image zoom on tile hover) */
.departments-listing .col-lg-4.col-md-6.mb-4 > a.rounded-pill:hover .department-image img {
  transform: scale(1.05); /* Slight zoom on hover */
}


.department-content {
  padding: 25px 30px; /* Increased padding inside content area */
}

.department-content h3 {
  font-size: 1.7em; /* Larger department name */
  margin-bottom: 12px;
  color: #200769; /* Dark blue department name */
  font-weight: 600; /* Semi-bold name */
  letter-spacing: -0.3px;
}

.department-head {
  font-size: 1.05em;
  color: #841810; /* Dark red for Head of Department label */
  margin-bottom: 10px;
  font-weight: 500; /* Medium font weight */
}

.department-description {
  color: #666; /* Slightly darker description text */
  line-height: 1.7;
  font-size: 1rem;
}

.department-footer {
  padding: 20px 30px;
  border-top: 1px solid #f0f0f0; /* Very light border for footer */
  text-align: right; /* Align button to the right */
  background-color: #fefefe; /* Slightly off-white footer background */
}

.department-footer .btn-outline-success {
  background-color: #f4b328; /* Yellow background for button */
  color: white; /* White button text */
  border: none; /* Remove default outline border */
  border-radius: 8px; /* Rounded button corners */
  padding: 12px 25px; /* Adjusted button padding */
  font-weight: 500; /* Medium font weight for button text */
  transition: background-color 0.3s ease; /* Smooth background transition */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle button shadow */
  display: inline-flex; /* Use flex to align icon and text */
  align-items: center; /* Vertically align icon and text */
}

.department-footer .btn-outline-success:hover {
  background-color: #d49b22; /* Darker yellow on hover */
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.department-footer .btn-outline-success i {
  margin-left: 8px; /* Spacing between text and icon */
  font-size: 0.9em;
}


/* Responsive adjustments for smaller screens */
@media (max-width: 992px) {
  .page-header {
    padding: 50px 0;
  }
  .page-header .page-name {
    font-size: 2.6em;
  }
  .department-content {
    padding: 20px;
  }
  .department-footer {
    padding: 15px 20px;
  }
}

@media (max-width: 768px) {
  .page-header {
    padding: 40px 0;
  }
  .page-header .page-name {
    font-size: 2.2em;
  }
  .department-item {
    margin-bottom: 20px;
  }
  .department-content {
    padding: 15px;
  }
  .department-footer {
    padding: 12px 15px;
  }
}



.courses-bullet-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.course-list-item {
  margin-bottom: 0.75rem;
  position: relative;
}

.course-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.course-link:hover {
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transform: translateX(5px);
}

.bullet-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #200769;
  margin-right: 16px;
  flex-shrink: 0;
}

.course-link:hover .bullet-point {
  background-color: #841810;
}

.course-bullet-title {
  font-weight: 500;
  letter-spacing: 0.5px;
  flex-grow: 1;
}

.course-arrow {
  color: #841810;
  opacity: 0;
  margin-left: 10px;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.course-link:hover .course-arrow {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .course-link {
    padding: 0.75rem;
  }
  
  .bullet-point {
    margin-right: 12px;
  }
  
  .course-title {
    font-size: 0.95rem;
  }
}